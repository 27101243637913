import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../../../context/GlobalContext';
import CourseCard from '../course-card/course-card.component';
import './student-courses.styles.scss';
import useStudentCourses from './use-student-courses.hook';

interface StudentCoursesProps {
	fetchAlunoRematricula: () => void;
	handleNovoCursoClick: (id: number[]) => void;
	matriculasEmAndamento: boolean;
}

export default function StudentCourses({
	fetchAlunoRematricula,
	handleNovoCursoClick,
	matriculasEmAndamento,
}: StudentCoursesProps) {
	const { isLoading, coursesData } = useStudentCourses();
	const [isMatricula, setIsMatricula] = useState(false);
	const [isRematricula, setIsRematricula] = useState(false);
	const { setIsGlobalLoading } = useGlobalContext();
	const [hasUpgradeAvailable, setHasUpgradeAvailable] = useState(false);

	const firstMatricula = coursesData.length === 0;

	const checkMatricula = () => {
		const regularCourses = coursesData.filter(({ turma }) =>
			turma?.curriculoNegocioTipoCurriculoCodigo === "RGL"
		);

		if (firstMatricula || regularCourses.length === 0) {
			setIsMatricula(true);
			return;
		}

		const finalizedMatriculas = regularCourses.every(({ statusMatricula }) => {
			return statusMatricula.id === 3;
		});

		if (finalizedMatriculas) return setIsMatricula(finalizedMatriculas);

		const contractsRematricula = regularCourses
			.filter(({ statusMatricula }) => statusMatricula.id !== 3)
			.every(({ dataInicioContrato }) => {
				const dateContractEnded = new Date(dataInicioContrato);
				dateContractEnded.setFullYear(dateContractEnded.getFullYear() + 1);

				const today = new Date();

				const minLimit = new Date(dateContractEnded);
				minLimit.setDate(minLimit.getDate() - 120);

				const maxLimit = new Date(dateContractEnded);
				maxLimit.setDate(maxLimit.getDate() + 120);

				return today >= minLimit && today <= maxLimit;
			});

		return setIsMatricula(!contractsRematricula)
	};

	const checkRematricula = () => {
		if (firstMatricula) {
			setIsRematricula(false)
			return;
		}

		setIsRematricula(matriculasEmAndamento);
	}

	const handleUpgradeAvailable = () => {
		setHasUpgradeAvailable(true);
	};

	useEffect(() => {
		if (matriculasEmAndamento != null) {
			if (coursesData.length > 0) {
				setIsGlobalLoading(true);
				Promise.all([checkRematricula(), checkMatricula()])
					.finally(() => setIsGlobalLoading(false))
			} else if (coursesData.length === 0) {
				setIsGlobalLoading(true);
				checkMatricula();
				setIsGlobalLoading(false);
			}
		}
	}, [coursesData, matriculasEmAndamento, isLoading])

	return (
		<div className={'student-basic-info-wrapper'}>
			<div className={'student-basic-info-wrapper__title-buttons'}>
				<h4>Cursos</h4>

				<div className={'title-buttons__buttons-container'}>
					{isMatricula && !isRematricula && !hasUpgradeAvailable &&
						<Button
							className="button-matricular"
							onClick={() => handleNovoCursoClick([2])}
						>
							Matrícula Regular
						</Button>
					}
					{isRematricula &&
						<Button
							className="button-matricular"
							onClick={() => fetchAlunoRematricula()}
						>
							Renovação
						</Button>
					}
					<Button
						className="button-matricular"
						onClick={() => handleNovoCursoClick([1, 12])}
					>
						Matrícula Curta/Colônia
					</Button>
				</div>
			</div>
			<div className={'student-basic-info-wrapper__courses-section'}>
				{!isLoading &&
					coursesData &&
					coursesData.map((course) => (
						<CourseCard key={course.id} course={course} onUpgradeAvailable={handleUpgradeAvailable} />
					))}
			</div>
		</div>
	);
}
